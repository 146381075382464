<template>
  <div class="user-center">
    <div class="banner">
      <div class="user-info" v-if="userInfo">
        <img v-if="userInfo && userInfo.avatar!=''" class="user-img" :src="userInfo.avatar" alt="">
        <img v-else class="user-img" src="~@/assets/head-default-personal.png" alt="">
        <div class="user-content">
          <div class="user-name">
            <span class="name">{{userInfo ? userInfo.name : ''}}</span>
            <!-- <span class="edit"></span> -->
            <!-- <span class="level">普通用户</span> -->
            <!-- <button class="certification">实名认证</button> -->
          </div>
          <div class="user-company" v-if="userInfo.company_name">{{userInfo.company_name || ''}}</div>
        </div> 
        <div class="statistics">
          <div class="sta mins">
            <div class="num">{{userInfo.total_duration || 0}}</div>
            <div class="title">学习时长</div>
          </div>
          <div class="sta collect">
            <div class="num">{{userInfo.collect_count || 0}}</div>
            <div class="title collect">收藏课程</div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-content">
      <div class="content clearfix">
        <Menu />
        <div class="content-right">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Menu from './menu'
import { mapState } from "vuex";
export default {
  name: 'UserCenter',
  components: {
    Menu,
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted(){
    // if(!this.userInfo){
    //   location.reload(); 
    // }

  },
  mounted(){
  },
  methods: {}
}
</script>

<style lang="scss">
.banner{
  width: 100%;
  height: 376px;
  background: url(~@/assets/top-bg.png) no-repeat center bottom;
  background-size: auto 100%;
  position: relative;
}
.user-info{
  width: 1200px;
  height: 150px;
  position: absolute;
  left: 50%;
  bottom: -14px;
  margin-left: -600px;
  overflow: hidden;
}
.user-img{
  width: 150px;
  height: 150px;
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  float: left;
  margin-right: 30px;
}
.user-content{
  width: 540px;
  color: #fff;
  float: left;
}
.user-name{
  padding-top: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  .name{
    font-size: 24px;
    font-weight: 800;
  }
  .edit{
    width: 24px;
    height: 24px;
    background: url(~@/assets/edit-ico.png) no-repeat;
    background-size: 100%;
    margin-left: 8px;
    cursor: pointer;
  }
  .level{
    font-size: 14px;
    margin-left: 60px;
  }
  .certification{
    width: 77px;
    height: 24px;
    line-height: 24px;
    background: #FFF;
    border-radius: 2px;
    font-size: 14px;
    color: #254ED4;
    margin-left: 34px;
    cursor: pointer;
  }
}
.user-company{
  display: inline-block;
  padding-right: 24px;
  font-size: 16px;
  font-weight: 900;
  color: #FFF;
  // background: url(~@/assets/arrows-down-white.png) right center no-repeat;
  // background-size: auto 14px;
  //cursor: pointer;
}
.statistics{
  overflow: hidden;
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  .sta{
    margin-left: 48px;
  }
  .num{
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 15px;
  }
  .title{
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before{
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background: url(~@/assets/clock-white-ico.png) no-repeat;
      background-size: 100%;
      margin-right: 8px;
    }
    &.collect::before{
      width: 20px;
      height: 20px;
      background: url(~@/assets/star-white-ico.png) no-repeat;
      background-size: 100%;
      left: -30px;
      top: 0;
    }
  }
}
.center-content{
  width: 100%;
  background-color: #F7F7F7;
  padding: 44px 0 88px;
  .content{
    width: 1200px;
    margin: 0 auto;
  }
}
.content-right{
  box-sizing: border-box;
  width: 818px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 12, 40, 0.14);
  border-radius: 1px;
  padding: 34px;
  float: right;
}
.center-title{
  font-size: 24px;
  font-weight: 800;
  color: #333;
  margin-bottom: 30px;
}
</style>
