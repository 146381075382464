<template>
  <div class="menu">
   
    <router-link to='/userCenter/material' class="sub material"  :class="$route.name=='Material'?'active':''">个人资料</router-link>
    <router-link to='/userCenter/learning' class="sub learning" :class="$route.name=='Learning'?'active':''">学习记录</router-link>
    <!-- <router-link to='/userCenter/accountSecurity' class="sub phone" :class="$route.name==''?'active':''">账号安全</router-link> -->
    <router-link v-if="comInfo.id != '55' && comInfo.id != '107'"  to='/userCenter/purchaseRecords' class="sub pay" :class="$route.name=='PurchaseRecords'?'active':''">购买记录</router-link>
    <router-link to='/userCenter/classInfo' class="sub class" :class="$route.name=='ClassInfo'?'active':''">班级信息</router-link>
    <!-- <router-link to='/userCenter/myTest' class="sub exam" :class="$route.name==''?'active':''">我的考试</router-link> -->
    <!-- <router-link to='/userCenter/message' class="sub message" :class="$route.name==''?'active':''">消息通知</router-link> -->
    <router-link to='/userCenter/collection' class="sub collect" :class="$route.name=='Collection'?'active':''">我的收藏</router-link>
    <router-link v-if="comInfo.credit_study_interval_minute != 0 && comInfo.credit_study_number != 0  && comInfo.credit_study_today_limit != 0 " to='/userCenter/mycredits' class="sub collect" :class="$route.name=='mycredits'?'active':''">我的学分</router-link>
    <router-link to='/userCenter/commonExam' class="sub learning" :class="$route.name=='commonExam'?'active':''">我的公开考试</router-link>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: 'UserMenu',
  data(){
    return{
      comInfo:''
    }
  },
  components: {
    
    
  },
   computed: {
    ...mapState(['userInfo'])
  },
  mounted(){
    this.comInfo = JSON.parse(localStorage.getItem('siteInfo'))
    // if(!this.userInfo){
    //   location.reload(); 
    // }

  },
}
</script>

<style lang="scss">
.menu{
  box-sizing: border-box;
  width: 352px;
  background: url(~@/assets/menu-bg.png) no-repeat center bottom #fff;
  background-size: 100% auto;
  float: left;
  .sub{
    display: block;
    padding-left: 110px;
    height: 54px;
    line-height: 58px;
    font-size: 18px;
    color: #333;
    margin-bottom: 4px;
    cursor: pointer;
    &.material{
      background: url(~@/assets/material-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/material-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.learning{
      background: url(~@/assets/learning-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/learning-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.phone{
      background: url(~@/assets/phone-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/phone-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.pay{
      background: url(~@/assets/pay-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/pay-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.class{
      background: url(~@/assets/class-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/class-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.exam{
      background: url(~@/assets/exam-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/exam-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.message{
      background: url(~@/assets/message-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/message-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.collect{
      background: url(~@/assets/collect-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/collect-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    a{
      font-size: 18px;
      color: #333;
    }
    &:hover,
    &.active{
      color: #fff;
    }
  }
}
</style>
